const formIOTranslations = {
  de: {
    required: '{{field}} ist obligatorisch.',
    error: 'Beheben Sie die angezeigten Fehler.',
    invalid_email: 'E-Mail-Adresse ist falsch.',
    currency: 'EUR',
    cancel: 'Stornieren',
    next: 'Nächster',
    previous: 'Bisherige',
    submit: 'Einreichen',
  },
  nl: {
    required: 'Dit veld is verplicht.', // you could use {{field}} here
    error: 'Los de aangegeven fouten op.',
    pattern: 'Controleer dit veld',
    invalid_email: 'E-mailadres is ongeldig',
    currency: 'EUR',
    Day: 'Dag',
    Month: 'Maand',
    Year: 'Jaar',
    invalid_day: 'Ongeldige dag',
    January: 'januari',
    February: 'februari',
    March: 'maart',
    April: 'april',
    May: 'mei',
    June: 'juni',
    July: 'juli',
    August: 'augustus',
    September: 'september',
    October: 'oktober',
    November: 'november',
    December: 'december',
    cancel: 'Annuleren',
    next: 'Volgende',
    previous: 'Vorige',
    submit: 'Versturen',
  },
};

export default formIOTranslations;
