/* eslint-disable func-names */
import Choises from 'choices.js/public/assets/scripts/choices';

function override(obj, methodName, callback) {
  // eslint-disable-next-line no-prototype-builtins
  if (obj.hasOwnProperty(methodName)) {
    /* eslint-disable-next-line no-param-reassign */
    obj[methodName] = callback(obj[methodName]);
  }
}

const eventsToBeFixed = [
  '_onClick',
  '_onKeyDown',
  '_onTouchEnd',
  '_onMouseDown',
  '_onMouseOver',
  '_onFocus',
  '_onBlur',
];

// monkeypatch the choices module that formio uses to render the select boxes
// the event handlers don't handle the events correctly when inside a shadow root
// the fix is not to use the event.target but the event.path[0]
// fucking rocket science man!
// eslint-disable-next-line no-restricted-syntax
for (const fixme of eventsToBeFixed) {
  override(
    Choises.prototype,
    fixme,
    original => function (event) {
      let { target } = event;
      if (event.path) {
        /* eslint-disable-next-line prefer-destructuring */
        target = event.path[0];
      } else if (event.originalTarget) {
        target = event.originalTarget;
      } else if (event.composedPath) { // safari
        // eslint-disable-next-line prefer-destructuring
        target = event.composedPath()[0];
      }
      Object.defineProperty(event, 'target', {
        writable: false,
        value: target,
      });
      /* eslint-disable-next-line prefer-rest-params */
      const returnValue = original.apply(this, arguments);
      return returnValue;
    },
  );
}

// CustomEvent polyfill voor IE11 (wordt gebruikt door choices)
/* eslint-disable-next-line consistent-return */
(function () {
  if (typeof window.CustomEvent === 'function') return false;
  function CustomEvent(event, params) {
    /* eslint-disable-next-line no-param-reassign */
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail,
    );
    return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
}());
