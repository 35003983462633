/**
 * Automatically fill street and housenumber fields based on postcode and housenumber
 *
 */

import axios from 'axios';

// Fields with the following values are expected in the form
export const fields = {
  postcode: 'postcode',
  houseNumber: 'huisnummer',
  street: 'straatnaam',
  city: 'plaats',
};
const apiUrl = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/free';
const postcodeRegex = '^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-zA-Z]{2}$';
const houseNumberRegex = '^[0-9]*';


class LocationService {
  constructor(postcode, houseNumber) {
    this.postcode = postcode.replace(' ', '');
    const match = houseNumber.match(houseNumberRegex);
    [this.houseNumber] = match;
    this.queryString = `?fq=postcode:${this.postcode}&fq=huisnummer:${this.houseNumber}`;
  }

  get(callbackFunction) {
    const url = apiUrl + this.queryString;
    axios.get(url)
      .then(callbackFunction);
  }
}


export class AddressAutofillHandler {
  constructor(root) {
    this.postcode = root.querySelector(`input[name="data[${fields.postcode}]"]`);
    this.houseNumber = root.querySelector(`input[name="data[${fields.houseNumber}]"]`);
    this.street = root.querySelector(`input[name="data[${fields.street}]"]`);
    this.city = root.querySelector(`input[name="data[${fields.city}]"]`);

    this.requiredFields = [this.postcode, this.houseNumber];
    this.allFields = [...this.requiredFields, this.street, this.city];

    // Check if all fields are present before continuing
    if (this.allFields.filter(x => x).length === Object.keys(fields).length) {
      const that = this;
      this.requiredFields.forEach((field) => {
        // Add event listener which listens when the focus is removed from field
        field.addEventListener('focusout', () => {
          if (that.validFields()) {
            const service = new LocationService(that.postcode.value, that.houseNumber.value);
            service.get(that.fillFields.bind(that));
          }
        });
      });
    }
  }

  validFields() {
    const validField = (field) => {
      // Field must not have focus
      if (field === document.activeElement) return false;
      // Field must not be empty
      if (field.value === '') return false;
      return true;
    };

    let valid = true;
    if (this.requiredFields.some(field => !validField(field))) {
      valid = false;
    }

    const postcodeRe = new RegExp(postcodeRegex);
    // Postcode field must be valid according to regex
    if (valid && !postcodeRe.test(this.postcode.value)) {
      valid = false;
    }

    const houseNumberRe = new RegExp(houseNumberRegex);
    // Postcode field must be valid according to regex
    if (valid && !houseNumberRe.test(this.houseNumber.value)) {
      valid = false;
    }

    return valid;
  }

  fillFields(response) {
    if (response.status === 200) {
      const { docs } = response.data.response;
      if (docs.length > 0) {
        const result = docs[0];
        this.street.value = result.straatnaam;
        this.city.value = result.woonplaatsnaam;
      }
    }
  }
}

// export default AddressAutofillHandler;
