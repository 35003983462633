// import 'es6-promise/auto';
import '@/patches/choices';
import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import PUForm from './components/PUForm.vue';

// Enable the plugin
Vue.use(vueCustomElement);

// Enable the plugin
Vue.use(PUForm);

// Register your component
Vue.customElement('pu-form', PUForm, {
  // Additional Options: https://github.com/karol-f/vue-custom-element#options
  shadow: true,
  // shadowCss: `@import '${process.env.VUE_APP_STATIC_URL}/pu-form.css';`,
});
